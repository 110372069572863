import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import LinkSowosWealthModerateCommand
  from '@/modules/flagship/investor-goal/link-sowos-wealth-moderate/application/commands/link-sowos-wealth-moderate-command';
import UpdateInvestorGoalRiskToleranceCommand
  from '@/modules/flagship/investor-goal-risk-tolerance/application/commands/update-investor-goal-risk-tolerance-command';
import NotifyInvestorGoalRiskToleranceCommand
  from '@/modules/flagship/investor-goal-risk-tolerance/application/commands/notify-investor-goal-risk-tolerance-command';
import InvestorGoalRiskToleranceDtoService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-dto-service';
import InvestorGoalRiskToleranceChoseWarningAnswerService
  from '@/modules/flagship/investor-goal-risk-tolerance/application/services/investor-goal-risk-tolerance-chose-warning-answer-service';

// Domain
import { LowToleranceNotificationDto }
  from '@/modules/flagship/investor-goal-risk-tolerance/domain/dto/low-tolerance-notification-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class LinkStrategyModerateTransferViewModel {
  @Inject(TYPES.LINK_SOWOS_WEALTH_MODERATE_COMMAND)
  private readonly link_sowos_wealth_moderate!: LinkSowosWealthModerateCommand;

  @Inject(TYPES.UPDATE_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
  private readonly update_investor_goal_risk_tolerance_cmd!: UpdateInvestorGoalRiskToleranceCommand;

  @Inject(TYPES.NOTIFY_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
  private readonly notify_investor_goal_risk_tolerance_cmd!: NotifyInvestorGoalRiskToleranceCommand;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_DTO_SERVICE)
  private readonly investor_goal_risk_tolerance_dto_service!: InvestorGoalRiskToleranceDtoService;

  @Inject(TYPES.INVESTOR_GOAL_RISK_TOLERANCE_CHOSE_WARNING_ANSWERS_SERVICE)
  private readonly investor_goal_risk_tolerance_chose_warning_answer_service!:
    InvestorGoalRiskToleranceChoseWarningAnswerService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly view: Vue;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.link-sws-moderate.link-strategy-moderate-transfer';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  is_loading = false;

  is_checkbox_active = false;

  is_accept_button_disabled = true;

  investor_goal_id = '';

  link_sowos_moderate_entity = {
    id: v4(),
    investor_goal_id: '',
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  setDynamicWidth = () => {
    this.view.$emit('setDynamicWidth', '35');
  }

  enableAcceptButton = () => {
    this.is_accept_button_disabled = !this.is_checkbox_active
      || !this.investor_goal_id || this.is_loading;
  }

  linkGoalWithModerate = async () => {
    try {
      this.is_loading = true;
      await this.link_sowos_wealth_moderate.execute(this.link_sowos_moderate_entity);
      await this.updateRiskToleranceAnswers();
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.link_moderate'));
    } finally {
      this.is_loading = false;
    }
  }

  getInvestorGoalRiskToleranceDto = () => (this.investor_goal_risk_tolerance_dto_service
    .getInvestorGoalRiskToleranceDto());

  getInvestorGoalRiskToleranceChoseWarningAnswer = () => (this
    .investor_goal_risk_tolerance_chose_warning_answer_service
    .getInvestorGoalRiskToleranceChoseWarningAnswer());

  updateRiskToleranceAnswers = async () => {
    try {
      this.is_loading = true;
      const investor_goal_risk_tolerance_dto = this.getInvestorGoalRiskToleranceDto();
      await this.update_investor_goal_risk_tolerance_cmd.execute(investor_goal_risk_tolerance_dto);
      if (this.getInvestorGoalRiskToleranceChoseWarningAnswer()) {
        await this.notifyUserChoseAWarningAnswer();
      }
    } catch {
      this.message_notifier.showErrorNotification('errors.update_risk_tolerance_answers');
    } finally {
      this.is_loading = false;
    }
  }

  notifyUserChoseAWarningAnswer = async () => {
    try {
      this.is_loading = true;
      const { customer_id, investor_goal_id, investment_product_fund_type_id } = this
        .getInvestorGoalRiskToleranceDto();
      const payload: LowToleranceNotificationDto = {
        customer_id,
        investor_goal_id,
        investment_product_fund_type_id,
      };
      await this.notify_investor_goal_risk_tolerance_cmd.execute(payload);
    } catch {
      this.message_notifier.showErrorNotification('errors.notify_investor_goal_risk_tolerance');
    } finally {
      this.is_loading = false;
    }
  }

  initialize = (investor_goal_id: string) => {
    this.investor_goal_id = investor_goal_id;
    this.link_sowos_moderate_entity.investor_goal_id = investor_goal_id;
    this.setDynamicWidth();
  }
}
