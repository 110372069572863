



































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import LinkStrategyModerateTransferViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-goal/link-strategy-moderate-transfer-view-model';

@Component({ name: 'LInkStrategyModerateTransfer' })
export default class LInkStrategyModerateTransfer extends Vue {
  @PropSync('investorGoalId', { type: String, required: true })
  synced_investor_goal_id!: string;

  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  strategy_transfer_view_model = Vue.observable(new LinkStrategyModerateTransferViewModel(this));

  created() {
    this.strategy_transfer_view_model.initialize(this.synced_investor_goal_id);
  }
}
